<template>
  <div class="fs-15 ar" style="line-height: 28px">

    <h3 class="font-weight-medium mb-5 grey--text text--darken-4">
      اشعار معلومات متعلق بحماية المعطيات ذات
      الطابع الشخصي
    </h3>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">مقدمة</h4>

    <p>
      في شركة فرويتال أو ("فرويتال")، نتبنى سياسة تركز
      على حماية الخصوصية والمعطيات الشخصية. بهذا الصدد، نحن
      نبلغكم بكل ما نقوم به بمعطياتكم الشخصية وكيفية حمايتها
      وفقا لأحكام القانون 18-07 المتعلق بحماية الأشخاص الطبيعيين
      في مجال معالجة المعطيات ذات الطابع الشخصي.<br>
      يهدف هذا الإشعار إلى إبلاغ الزبائن عن كيفية معالجة فرويتال
      لمعطياتكم الشخصية.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">أنواع المعطيات المجمعة</h4>

    <p>
      تقوم فرويتال بجمع واستخدام مختلف أنواع المعطيات
      الشخصية:<br>
      -معطيات تتعلق بالهوية والوضع العائلي؛<br>
      -معطيات تتعلق بالوضع الاقتصادي والمالي؛<br>
      -معطيات المعاملات )تاريخ الشراء، تفاصيل المنتجات أو
      الخدمات المشتراة، تواريخ ومبالغ المعاملات، إلخ(؛
      -معطيات الموقع )بيانات GPS، عناوين التسليم، موقع المتجر،
      إلخ(؛<br>
      -أي معلومات قد يقدمها الزبون بارادته إلى فرويتال في إطار
      استطلاعات الرأي، وطلبات دعم الزبائن، وما إلى ذلك.<br>
      في بعض الحالات، قد يكون من الممكن أن تقوم فرويتال بجمع
      المعلومات دون أن تكون في علاقة مباشرة معك. قد يكون ذلك
      مثلا عندما تكون لدينا معلومات الاتصال الخاصة بك من موردينا،
      مقدمي الخدمة، أوالزبائن.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">أغراض جمع ومعالجة بحماية المعطيات ذات الطابع
      الشخصي</h4>

    <p>
      يتم جمع المعطيات الشخصية لأغراض صريحة ومشروعة
      ومحددة. <br>
      تستخدم المعطيات الشخصية المجمعة في:<br>
      -الجهود التي تبذلها فرويتال استجابة لالتزاماتها القانونية
      والتنظيمية:<br>
      -في إطار علاقتك التعاقدية للأغراض الرئيسية التالية:<br>
      * تدبير وإدارة وتنفيذ عقودك مع فرويتال؛<br>
      * إدارة العمليات التجارية للعملاء والتسويق التجاري من
      خلال إجراءات تعزيز الولاء، ومتابعة وتحسين جودة
      العلاقة مع الزبائن )استطلاعات الرضا، رسائل البريد
      الإلكتروني، الرسائل النصية، التواصل عبر وسائل
      التواصل الاجتماعي، أي وسائل أخرى...(.<br>
      -متابعة المصالح المشروعة:<br>
      * إدارة نظام المعلومات؛<br>
      * تخصيص العروض.
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">مشاركة وإرسال المعطيات</h4>

    <p>
      من أجل تحقيق أغراض المعالجة، قد نضطر إلى مشاركة
      معطياتك الشخصية مع مقاولينا الفرعيين، وشركات التأمين
      ومقدمي الخدمات المكلفين بتنفيذ خدمات تتعلق بالأغراض
      المحددة أعلاه. هذه الكيانات ملزمة تعاقديا بالالتزام بسرية
      وأمان المعطيات التي بحوزتها والتي لديها وصول إليها،
      واستخدامها حصريا في إطار المهام الموكلة إليهم.
      قد تلجأ فرويتال أيضا إلى مقدمي خدمات متواجدين خارج
      الجزائر.<br>
      يرجى ملاحظة أن أي نقل للمعطيات إلى الخارج يخضع لموافقة
      السلطة الوطنية المختصة
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline"> مدة الاحتفاظ بالمعطيات الشخصية
    </h4>

    <p>
      بشكل عام، يتم الاحتفاظ بمعطياتك الشخصية أثناء مدة علاقتك
      التعاقدية مع فرويتال وقد يتم أرشفتها وفقا للآجال القانونية
      والتنظيمية المعمول بها للامتثال لالتزاماتنا القانونية، وحل
      النزاعات والمطالبات، والوقاية من الاحتيال وسوء الاستخدام،
      وتنفيذ اتفاقياتنا أو حماية مصالحنا المشروعة.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">حماية المعطيات الشخصية</h4>

    <p>
      حماية معطياتك الشخصية هي من أولوياتنا. لقد قمنا بوضع
      تدابير أمنية تقنية وتنظيمية مناسبة لمنع أي فقدان أو سوء
      استخدام أو وصول غير مصرح به أو كشف أو تغيير لمعطياتك.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">المراقبة والأمان</h4>

    <p>
      من أجل تحقيق أقصى درجات الأمان، يتم مراقبة الفضاءات
      الداخلية والخارجية لفرويتال عن طريق كاميرات مراقبة.
      تحتفظ الشركة بسجلات التحركات لمدة 90يوما وقد تستخدمها
      في حالة الحاجة وفقا للقوانين السارية.
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">حقوقكم</h4>

    <p>
      الحق في الاعلام: طبقا للمادة 32 من القانون رقم 18-07 ، <br>
      الحق في الولوج: طبقا للمادة 34 من القانون رقم 18-07،<br>
      الحق في التصحيح: طبقا للمادة 35 من القانون رقم 18-
      07،<br>
      الحق في الاعتراض: طبقا للمادة 36 من القانون رقم 18-
      07،<br>
      إذا كنت ترغب في ممارسة أي من الحقوق المدرجة أعلاه،
      ندعوك لإرسال طلبك عبر البريد الالكتروني أدناه:
      <a href="mailto:Fruital_rrt@algeria.eccbc.com">Fruital_rrt@algeria.eccbc.com</a><br>
      لكي يكون طلبك قابل للنظر، يرجى إرسال نسخة مصورة /
      مسح ضوئي لوثيقة هويتك مع طلبك حتى نتمكن من الحصول
      على دليل على هويتك
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">بيان الموافقة</h4>

    <p>
      من خلال التوقيع على هذا الإشعار، تعبر عن موافقتك الصريحة
      لفرويتال على معالجة معطياتك الشخصية، بما في ذلك
      المعطيات الشخصية الحساسة إن لزم الأمر، بواسطة وسائل
      آلية أو يدوية، وإرسال معطياتك الشخصية، بما في ذلك
      المعطيات الشخصية الحساسة إن لزم الأمر، إلى دول أخرى
      لأغراض إدارة الحسابات والإدارة والأعمال التجارية، وللامتثال
      للالتزامات القانونية للشركة.<br>
      لقد قرأت جميع شروط وأحكام هذا الإشعار. أوافق على
      استخدام معطياتي الشخصية للأغراض وبالوسائل السابقة
      المذكورة.
    </p>


  </div>
</template>

<script>
export default {
    name: "DataProtectionAr"
}
</script>

<style scoped>

</style>