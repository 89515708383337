<template>
  <div :style="{minHeight: '100vh'}" class="white">


    <div class="shadow d-flex justify-space-between align-center px-8">

      <img :src="require('@/assets/logo.jpg')"
           :width="$vuetify.breakpoint.mobile ? '90' :  '80'"
           alt="">
      <v-spacer/>


      <v-btn class="bg-primary-subtle"
             color="primary"
             dark
             depressed
             text
             @click="lang === 'fr' ? [lang = 'ar' ,$vuetify.rtl = true]: [lang = 'fr',$vuetify.rtl = false]"
      >
        <span v-if="lang === 'ar'">Langue française</span>
        <span v-else class="ar">اللغة العربية</span>
      </v-btn>


    </div>


    <v-container fluid>

      <Overlay :overlay="btnLoading"/>

      <v-row justify="center">
        <v-col cols="11" lg="5">
          <v-card class="transparent" flat>

            <v-card-text v-if="$route.query.key && !invalidKey" class="text-center pa-0">

              <img :src="require('@/assets/sms.svg')"
                   :width="$vuetify.breakpoint.mobile ? '150' :  '200'"
                   alt="">

              <h1 class="my-4">
                Coca Plus
              </h1>

              <div v-if="!success">

                <p class="mb-1 pt-3 fs-14">

                  <span v-if="lang === 'fr'">
                    Accepter de recevoir des notifications SMS de la part de Fruital SPA dans le cadre du programme de fidélité MyCocaCola.
                  </span>

                  <span v-else class="ar">
                       توافق على تلقي الرسائل القصيرة من  شركة فرويتال كجزء من برنامج الوفاء كوكاكولا
                  </span>

                </p>

                <v-checkbox v-model="approve" class="d-inline-flex" color="success" label="">
                  <template v-slot:label>
                    <div>

                      <span v-if="lang === 'fr'">
                         J'ai lu et j'accepte
                      </span>

                      <span v-else class="ar">
                        لقد قرأت وقبلت
                       </span>

                      <a class="font-weight-medium" @click.stop="dialog = true">

                        <span v-if="lang === 'fr'">
                            les conditions générales
                        </span>

                        <span v-else class="ar">
                        الشروط العامة
                         </span>

                      </a>
                    </div>
                  </template>
                </v-checkbox>


                <div class="d-block mt-2">
                  <v-btn :disabled="!approve"
                         block
                         color="success"
                         depressed
                         @click="save(true)">
                    <span v-if="lang === 'fr'">
                           Valider
                        </span>

                    <span v-else class="ar">
                         حفظ
                         </span>
                  </v-btn>
                </div>

              </div>

              <div v-else>
                <v-icon color="success" size="70">mdi-check-circle</v-icon>
                <p class="pt-4 fs-14">

                  <span v-if="lang === 'fr'">
                             Approuvé avec succès, Merci.
                        </span>

                  <span v-else class="ar">
                         تمت الموافقة بنجاح، شكرًا.
                         </span>
                </p>
              </div>

            </v-card-text>

            <v-card-text v-else class="text-center pa-0">

              <img :src="require('@/assets/400.svg')"
                   :width="$vuetify.breakpoint.mobile ? '150' :  '300'"
                   alt="">

              <h1 class="my-4">

                <span v-if="lang === 'fr'">
                                Erreur 400 !
                        </span>

                <span v-else class="ar">
                       خطأ 400!
                    </span>

              </h1>

              <p class="pb-4 fs-14">

                <span v-if="lang === 'fr'">
                               URL invalide.
                        </span>

                <span v-else class="ar">
                          الرابط غير صحيح!
                    </span>
              </p>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="900" scrollable>
        <v-card>
          <v-card-title>
             <span v-if="lang === 'fr'">
                          Conditions générales
                 </span>

            <span v-else class="ar">
                        الشروط العامة
               </span>
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-6">
            <DataProtectionAr v-if="lang === 'ar'"/>
            <DataProtectionFr v-else/>
          </v-card-text>
        </v-card>
      </v-dialog>


    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import DataProtectionAr from "@/components/DataProtectionAr.vue";
import DataProtectionFr from "@/components/DataProtectionFr.vue";

export default {
    components: {DataProtectionFr, DataProtectionAr},
    data() {
        return {
            btnLoading: false,
            approve: false,
            success: false,
            invalidKey: false,
            dialog: false,
            errors: {},
            lang: 'fr',
        }
    },
    methods: {
        save(isApproved) {
            this.btnLoading = true
            this.errors = true
            axios.post(process.env.VUE_APP_BASE_URL + '/api/approve', {
                is_approved: isApproved,
                key: this.$route.query.key,
            }).then(() => {
                this.btnLoading = false
                this.success = true
            }).catch(err => {
                this.btnLoading = false

                if (err.response.status === 404) {
                    this.invalidKey = true
                }

                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        }
    },
}
</script>

<style scoped>

</style>