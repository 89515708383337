<template>
  <div class="fs-14" style="line-height: 28px">

    <h3 class="font-weight-medium mb-5 grey--text text--darken-4">
      Notice sur la protection des données à
      caractère personnel
    </h3>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">Introduction</h4>

    <p>
      Chez Fruital, nous adhérons à une politique
      centrée sur la protection de la vie privée et des
      données à caractère personnel. A cet effet, nous
      vous informons de tout ce que nous faisons avec
      vos données personnelles et comment nous les
      protégeons conformément aux dispositions de la
      loi 18-07.
      L’objet de la présente notice d'information est
      d’informer nos clients (« Vous ») sur la manière
      dont Fruital traite vos Données Personnelles.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">Catégories des données personnelles
      collectées</h4>

    <p>
      Données Personnelles, y compris :<br>

      - Des données relatives à l'identification et à
      l’état civil ; <br>

      -Données relatives à la situation économique et
      financière ;<br>

      - Données transactionnelles (par exemple,
      historique des achats, détails des produits ou
      services achetés, dates et montants des
      transactions, etc.) ;<br>

      - Données de localisation (par exemple, données
      GPS, adresses de livraison, localisation en
      magasin, etc.) ;<br>

      - Toute information que le client fournit
      volontairement à Fruital dans le cadre de
      sondages, demandes de support client, etc.
      Dans certains cas, il est possible que Fruital
      collecte des informations sans être en relation
      directe avec vous. Cela peut, par exemple, être le
      cas lorsque vos coordonnées nous sont délivrées
      par un de nos fournisseurs, prestataires ou
      clients.
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">Finalité de la collecte des données à
      caractère personnel
    </h4>

    <p>
      Les données à caractère personnel sont
      collectées dans le cadre de finalités explicites,
      légitimes et précises.
      Les données à caractère personnel recueillies
      sont utilisées : <br>
      - Dans le cadre du respect d'obligations légales
      et règlementaire auxquelles Fruital est
      soumise. <br>
      - Dans le cadre de votre relation contractuelle
      pour les principales finalités suivantes :
      <br>
      - La passation, la gestion et l'exécution de vos
      contrats avec Fruital ; <br>
      - La gestion commerciale des clients et à la
      prospection commerciale au travers
      notamment d'actions de fidélisation, du suivi
      et d'amélioration de la qualité de la relation
      client (enquêtes de satisfaction, e-mails, SMS,
      communication sur les réseaux sociaux, toute
      autre communication, ...) ;
      <br>
      - Dans le cadre de la poursuite d'un intérêt
      légitime :
      <br>
      - La gestion du système d'information ;
      <br>
      - La personnalisation des offres.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Partage des Données Personnelles
    </h4>

    <p>
      Dans le cadre des finalités définies ci-dessus, il
      se peut que nous communiquions vos Données
      Personnelles à nos sous-traitants, assureurs et
      prestataires de services qui sont chargés
      d'effectuer des prestations en relation avec nos
      objectifs. Cependant, nous exigeons
      contractuellement de ces entités de respecter la
      confidentialité et la sécurité des données
      auxquelles elles ont accès, en les utilisant
      exclusivement dans le cadre des missions qui
      leur sont confiées.
      Fruital peut également faire appel à des
      prestataires situés en dehors de l'Algérie.
      Veuillez noter que tout transfert vers l’étranger
      est soumis à l’autorisation préalable de l’Autorité
      nationale
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Durée de conservation des données
      personnelles
    </h4>

    <p>
      De manière générale, vos Données Personnelles
      sont conservées pendant la durée de votre
      relation contractuelle avec Fruital et peuvent être
      archivées conformément aux délais légaux et
      réglementaires applicables afin de nous
      conformer à nos obligations légales, résoudre nos
      litiges et nos réclamations, prévenir la fraude et
      les abus, appliquer nos accords ou protéger nos
      intérêts légitimes.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Protection des données personnelles
    </h4>

    <p>
      La protection de vos Données Personnelles est de
      la plus haute importance pour nous. Afin de
      prévenir toute perte, utilisation abusive, accès
      non autorisé, divulgation ou altération de vos
      données, nous avons mis en place des mesures
      de sécurité physiques, techniques et
      organisationnelles appropriées.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Surveillance, sureté et sécurité
    </h4>

    <p>
      Afin d'assurer une sécurité optimale, Fruital
      dispose d'une surveillance vidéo à la fois pour
      ses espaces intérieurs et extérieurs. Les
      enregistrements des mouvements sont
      conservés pendant 30 jours, et ils peuvent être
      exploités si nécessaire, en conformité avec les
      réglementations en vigueur.
    </p>


    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Vos droits
    </h4>

    <p>
      Conformément à la Loi 18-07, vous disposez des
      droits suivants : <br>
      - Droit à l’information (articles 32 et 33) ; <br>
      - Droit d’accès (article 34) ; <br>
      -Droit de rectification et d’effacement (article
      35) ; <br>
      -Droit d’opposition (article 36) ; <br>

      Si vous souhaitez exercer l'un des droits
      énumérés ci-dessus,<br> veuillez adresser votre
      demande par e-mail à : <a href="mailto:Fruital_rrt@algeria.eccbc.com">Fruital_rrt@algeria.eccbc.com</a>
      <br>
      Afin de rendre votre demande recevable, veuillez
      inclure une photocopie ou un scan de votre pièce
      d'identité, ce qui nous permettra de vérifier votre
      identité de manière sécurisée.
    </p>

    <h4 class="font-weight-medium mb-2 red--text text-decoration-underline">
      Déclaration de consentement
    </h4>

    <p>
      En signant la présente Notice, vous donnez votre
      consentement explicite à Fruital pour traiter vos
      Données Personnelles, y compris les Données
      Personnelles sensibles si nécessaire, par voie
      automatique ou manuelle, et pour transférer vos
      Données Personnelles, y compris les Données
      Personnelles sensibles si nécessaire, vers
      d’autres pays à des fins de gestion des comptes,
      d'administration et de gestion commerciale, et
      afin de se conformer aux obligations légales de
      Fruital. <br>
      J'ai pris connaissance de tous les termes et
      conditions de la présente Notice. Je consens à
      l'utilisation de mes données personnelles aux fins
      et par les moyens mentionnés ci-dessus.
    </p>
  </div>
</template>

<script>
export default {
    name: "DataProtectionFr"
}
</script>

<style scoped>

</style>